import React, { useEffect, useState } from 'react';
import { Form, Formik, FormikHelpers, FormikValues } from 'formik';
import { Box, Button, Flex, Heading, Stack } from '@chakra-ui/react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { CheckCircleIcon } from '@chakra-ui/icons';
import { useAuth } from '../AuthProvider/AuthProvider';
import { PasswordField } from '../AuthPage/PasswordField';
import { useTranslation } from 'react-i18next';
import { validationResetPasswordSchema } from 'src/shared/lib';

const ResetPasswordForm: React.FC = React.memo(() => {
  const { t } = useTranslation('editProfile');
  const { resetPassword, resetPasswordError, resetPasswordAuthorizedError } =
    useAuth();
  const [complete, setComplete] = useState(false);
  const params = useSearchParams();
  const nav = useNavigate();
  const [error, setError] = useState<string | undefined>();
  const handleSubmit = async (
    values: FormikValues,
    actions: FormikHelpers<FormikValues>
  ) => {
    const code = params[0].get('code') ?? undefined;
    await resetPassword(values.newPassword, actions, code);
    setComplete(true);
  };
  useEffect(() => {
    setError(resetPasswordError?.message);
  }, [resetPasswordError]);
  useEffect(() => {
    setError(resetPasswordAuthorizedError?.message);
  }, [resetPasswordAuthorizedError]);
  useEffect(() => {
    if (window.location.pathname.startsWith('/auth')) {
      const code = params[0].get('code') as string;
      if (!code) nav('/');
    }
  }, [nav, params]);
  return (
    <Formik
      initialValues={{ newPassword: '', repeatNewPassword: '' }}
      validationSchema={validationResetPasswordSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting }) => (
        <Form>
          {complete ? (
            <Box textAlign="center" py={10} px={6}>
              <CheckCircleIcon boxSize={'50px'} color={'blue.500'} />
              <Heading as="h2" size="xl" mt={6} mb={2}>
                Congratulations
              </Heading>

              <Link to={'/'}>
                <Button mt={'2rem'} colorScheme="blue" onClick={() => nav('/')}>
                  OK
                </Button>
              </Link>
            </Box>
          ) : (
            <Stack fontSize="sm" fontWeight="semibold">
              <Flex direction={'column'} mb="8">
                <Box>
                  <PasswordField isResetPasswordForm />
                </Box>
                <Box mt={'1.5rem'}>
                  <PasswordField isResetPasswordForm isRepeat />
                </Box>
              </Flex>
              <Button
                colorScheme="blue"
                type={'submit'}
                isLoading={isSubmitting}
              >
                {t('reset')}
              </Button>
              {error && (
                <Box
                  width={'100%'}
                  bg={'red.500'}
                  color={'white'}
                  p={'2'}
                  textAlign={'center'}
                  borderRadius={'10px'}
                >
                  {error}
                </Box>
              )}
            </Stack>
          )}
        </Form>
      )}
    </Formik>
  );
});

export default ResetPasswordForm;
