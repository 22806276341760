import React from 'react';
import {
  useReactTable,
  ColumnFiltersState,
  getCoreRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getPaginationRowModel,
  getSortedRowModel,
  FilterFn,
  ColumnDef
} from '@tanstack/react-table';

import { RankingInfo, rankItem } from '@tanstack/match-sorter-utils';

import ReactTable from 'src/components/Table/ReactTable';
import useAppStore from 'src/store';
import { IRecommendation } from 'src/types/types';
import {
  Button,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  useBreakpointValue,
  Badge,
  Text,
  Stack
} from '@chakra-ui/react';
import { DeleteIcon, EditIcon } from '@chakra-ui/icons';
import Alert from 'src/components/Alert/Alert';
import { HiOutlineDotsVertical } from 'react-icons/hi';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { formatDistance } from 'date-fns';
import enGB from 'date-fns/locale/en-GB';
import es from 'date-fns/locale/es';
import { fr } from 'date-fns/locale';

declare module '@tanstack/table-core' {
  interface FilterFns {
    fuzzy: FilterFn<unknown>;
  }
  interface FilterMeta {
    itemRank: RankingInfo;
  }
}

const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
  // Rank the item
  const itemRank = rankItem(row.getValue(columnId), value);

  // Store the itemRank info
  addMeta({
    itemRank
  });

  // Return if the item should be filtered in/out
  return itemRank.passed;
};

export const RecommendationsTable: React.FC<{
  handleDelete: (id: string) => {};
}> = ({ handleDelete }) => {
  const { t, i18n } = useTranslation('myCVs');
  const columnVisibility:
    | {
        resume: boolean;
        isApproved: boolean;
        updatedAt: boolean;
        company: boolean;
      }
    | undefined = useBreakpointValue({
    base: {
      resume: true,
      isApproved: false,
      updatedAt: false,
      company: false
    },
    sm: {
      resume: true,
      isApproved: false,
      updatedAt: false,
      company: false
    },
    md: {
      resume: true,
      isApproved: false,
      updatedAt: true,
      company: true
    },
    lg: {
      resume: true,
      isApproved: true,
      updatedAt: true,
      company: true
    }
  });
  const recommendations = useAppStore((state) => state.recommendations);
  const dateLocale: Record<string, Locale> = React.useMemo(
    () => ({
      en: enGB,
      es: es,
      fr: fr
    }),
    []
  );

  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );
  const [globalFilter, setGlobalFilter] = React.useState('');

  const columns = React.useMemo<ColumnDef<IRecommendation, any>[]>(
    () => [
      {
        accessorKey: 'id',
        cell: (props) => {
          const value = props.getValue();

          return (
            <Stack gap={2}>
              <Text maxW={24} noOfLines={1}>
                {value}
              </Text>
              {!columnVisibility?.isApproved ? (
                <Badge
                  textTransform="uppercase"
                  fontSize="xs"
                  colorScheme={props.row.original.isApproved ? 'green' : 'gray'}
                  maxW={32}
                  textAlign="center"
                  borderRadius={20}
                >
                  {props.row.original.isApproved
                    ? 'Approved'
                    : t('filterByCv.draft')}
                </Badge>
              ) : null}
            </Stack>
          );
        }
      },
      {
        accessorKey: 'resume',
        header: () => 'resume',
        cell: (props) => {
          const { resume } = props.row.original;
          return (
            <Link to={`/my-cvs/${resume.id}`}>
              {resume?.title || resume.id}
            </Link>
          );
        }
      },
      {
        accessorKey: 'isApproved',
        header: t('table.status'),
        enableColumnFilter: false,
        accessorFn: (row) =>
          row.isApproved ? 'Approved' : t('filterByCv.draft'),
        cell: (props) => {
          const value = props.getValue();
          return (
            <Badge
              textTransform="uppercase"
              fontSize="xs"
              colorScheme={props.row.original.isApproved ? 'green' : 'gray'}
            >
              {value}
            </Badge>
          );
        }
      },
      {
        accessorKey: 'updatedAt',
        header: t('table.lastUpdate'),
        enableColumnFilter: false,
        accessorFn: (row) =>
          formatDistance(new Date(row.updatedAt), Date.now(), {
            includeSeconds: true,
            addSuffix: true,
            locale: dateLocale[i18n.language] ?? enGB
          })
      },
      {
        accessorKey: 'company',
        header: () => 'Context company',
        accessorFn: (row) => row.company || ''
      },
      {
        accessorKey: 'recommender',
        header: () => 'Recomender name',
        accessorFn: (row) => row.recommender?.name || ''
      },
      {
        accessorKey: 'id',
        header: () => null,
        enableColumnFilter: false,
        enableSorting: false,
        cell: (props) => {
          const id = props.getValue();

          return (
            <Menu direction={'ltr'}>
              <MenuButton
                as={IconButton}
                icon={<HiOutlineDotsVertical />}
                variant="ghost"
                _hover={{
                  bg: 'gray.300'
                }}
                onClick={(e) => e.stopPropagation()}
              />
              <MenuList onClick={(e) => e.stopPropagation()} p={0}>
                <MenuItem p="0" as={Link} to={`/recommendations/${id}`}>
                  <Button
                    leftIcon={<EditIcon />}
                    variant="ghost"
                    colorScheme="blue"
                    w="100%"
                  >
                    Edit
                  </Button>
                </MenuItem>
                <MenuDivider m={0} />
                <MenuItem
                  as={() => (
                    <Alert
                      title="Delete Recommendation"
                      body={
                        'Are you sure you want to delete the recommendation?'
                      }
                      submitText={'Confirm'}
                      cancelText={'Cancel'}
                      submitColorScheme={'red'}
                      submitCallback={() => handleDelete(id)}
                    >
                      <Button
                        leftIcon={<DeleteIcon />}
                        variant="ghost"
                        colorScheme="red"
                        w="100%"
                      >
                        Delete
                      </Button>
                    </Alert>
                  )}
                  p="0"
                />
              </MenuList>
            </Menu>
          );
        }
      }
    ],
    [t, columnVisibility?.isApproved, dateLocale, i18n.language, handleDelete]
  );

  const table = useReactTable<IRecommendation>({
    data: recommendations,
    columns,
    filterFns: {
      fuzzy: fuzzyFilter
    },
    state: {
      columnFilters,
      globalFilter,
      columnVisibility
      // pagination: {
      //   pageIndex: 0,
      //   pageSize: 6,
      // },
    },
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: fuzzyFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    debugTable: true,
    debugHeaders: true,
    debugColumns: false
  });

  return (
    <ReactTable<IRecommendation>
      table={table}
      searchValue={globalFilter}
      onSearchHandler={(value) => setGlobalFilter(String(value))}
    />
  );
};

export default React.memo(RecommendationsTable);
