import React from 'react';

import {
  useReactTable,
  ColumnFiltersState,
  getCoreRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getPaginationRowModel,
  getSortedRowModel,
  FilterFn,
  ColumnDef
} from '@tanstack/react-table';
import {
  Button,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList
} from '@chakra-ui/react';
import { DeleteIcon, EditIcon } from '@chakra-ui/icons';
import Alert from 'src/components/Alert/Alert';
import { HiOutlineDotsVertical } from 'react-icons/hi';
import { Link } from 'react-router-dom';
import { ICoverLetter } from '../../types/types';
import { formatDistance } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { enGB, fr, es } from 'date-fns/locale';
import ReactTable from 'src/components/Table/ReactTable';
import useAppStore from 'src/store';
import { RankingInfo, rankItem } from '@tanstack/match-sorter-utils';

declare module '@tanstack/table-core' {
  interface FilterFns {
    fuzzy: FilterFn<unknown>;
  }
  interface FilterMeta {
    itemRank: RankingInfo;
  }
}

const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
  // Rank the item
  const itemRank = rankItem(row.getValue(columnId), value);

  // Store the itemRank info
  addMeta({
    itemRank
  });

  // Return if the item should be filtered in/out
  return itemRank.passed;
};

const CoverLettersTable: React.FC<{ handleDelete: (id: string) => void }> = ({
  handleDelete
}) => {
  const { t, i18n } = useTranslation('recommendations');
  const dateLocale: Record<string, Locale> = React.useMemo(
    () => ({
      en: enGB,
      es: es,
      fr: fr
    }),
    []
  );
  const coverLetters = useAppStore((state) => state.coverLetters);

  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );
  const [globalFilter, setGlobalFilter] = React.useState('');
  const columns = React.useMemo<ColumnDef<ICoverLetter, any>[]>(
    () => [
      {
        accessorKey: 'title',
        header: () => t('table.title'),
        accessorFn: (row) => (row.title ? row.title : 'Untitled')
      },
      {
        accessorKey: 'application',
        header: () => 'Application',
        accessorFn: (row) => {
          const { application } = row;
          return application && application?.company
            ? `${application?.company} ${
                application?.title ? `(${application?.title})` : ''
              }`
            : '';
        }
      },
      {
        accessorKey: 'updatedAt',
        header: () => t('table.lastUpdate'),
        enableColumnFilter: false,
        accessorFn: (row) =>
          formatDistance(new Date(row.updatedAt), Date.now(), {
            includeSeconds: true,
            addSuffix: true,
            locale: dateLocale[i18n.language] ?? enGB
          })
      },
      {
        accessorKey: 'id',
        header: () => null,
        enableColumnFilter: false,
        enableSorting: false,
        cell: (props) => {
          const id = props.getValue();

          return (
            <Menu direction={'ltr'}>
              <MenuButton
                as={IconButton}
                icon={<HiOutlineDotsVertical />}
                variant="ghost"
                _hover={{
                  bg: 'gray.300'
                }}
                onClick={(e) => e.stopPropagation()}
              />
              <MenuList onClick={(e) => e.stopPropagation()} p={0}>
                <MenuItem p="0" as={Link} to={`/cover-letters/${id}`}>
                  <Button
                    leftIcon={<EditIcon />}
                    variant="ghost"
                    colorScheme="blue"
                    w="100%"
                  >
                    Edit
                  </Button>
                </MenuItem>
                <MenuDivider m={0} />
                <MenuItem
                  as={() => (
                    <Alert
                      title="Delete Application"
                      body={'Are you sure you want to delete the application?'}
                      submitText={'Confirm'}
                      cancelText={'Cancel'}
                      submitColorScheme={'red'}
                      submitCallback={() => handleDelete(id)}
                    >
                      <Button
                        leftIcon={<DeleteIcon />}
                        variant="ghost"
                        colorScheme="red"
                        w="100%"
                      >
                        Delete
                      </Button>
                    </Alert>
                  )}
                  p="0"
                />
              </MenuList>
            </Menu>
          );
        }
      }
    ],
    [dateLocale, handleDelete, i18n.language, t]
  );

  const table = useReactTable<ICoverLetter>({
    data: coverLetters,
    columns,
    filterFns: {
      fuzzy: fuzzyFilter
    },
    state: {
      columnFilters,
      globalFilter
    },
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: fuzzyFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    debugTable: true,
    debugHeaders: true,
    debugColumns: false
  });

  return (
    <ReactTable<ICoverLetter>
      table={table}
      searchValue={globalFilter}
      onSearchHandler={(value) => setGlobalFilter(String(value))}
    />
  );
};

export default CoverLettersTable;
