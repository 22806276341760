import React from 'react';

import 'driver.js/dist/driver.css';
import { Box, Button, Flex, Heading } from '@chakra-ui/react';
import { ApolloError, useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAppToast } from 'src/hooks/useAppToast';
import {
  CREATE_COVER_LETTER,
  DELETE_COVER_LETTER
} from 'src/graphql/mutations';
import CoverLettersTable from './CoverLettersTable';
import useAppStore from 'src/store';

const CoverLettersPage: React.FC = () => {
  const { t } = useTranslation('coverLetters');
  const nav = useNavigate();
  const { showToast, closeToast } = useAppToast('coverLetters');
  const initCoverLetters = useAppStore((state) => state.initCoverLetters);
  const coverLetters = useAppStore((state) => state.coverLetters);
  const setCoverLetters = useAppStore((state) => state.setCoverLetters);
  const onError = async (error: ApolloError) => {
    await closeToast();
    showToast({ content: error.message, status: 'error' });
  };

  const [createCoverLetter] = useMutation(CREATE_COVER_LETTER, {
    onCompleted: async (data) => {
      await closeToast();
      if (data.createCoverLetter) {
        nav(`/cover-letters/${data.createCoverLetter.id}`);
      }
    },
    onError
  });

  const [deleteCoverLetterMutation] = useMutation(DELETE_COVER_LETTER, {
    onCompleted: (data, options) => {
      if (data.removeCoverLetter) {
        setCoverLetters(
          coverLetters.filter((cl) => cl.id !== options?.variables?.id)
        );
        showToast({
          content: 'Cover letter succesfully removed',
          status: 'success'
        });
      }
    },
    onError
  });

  const handleCreateCoverLetter = async () => {
    showToast({
      content: 'Create cover letter in progress',
      status: 'loading',
      duration: null
    });
    await createCoverLetter();
  };

  React.useEffect(() => {
    initCoverLetters();
  }, [initCoverLetters]);

  return (
    <Box as="section" py="1">
      <Box maxW="7xl">
        <Box w={'100%'}>
          <Flex justifyContent="space-between">
            <Heading size="lg" mb="6">
              {t('title')}
            </Heading>
            <Button onClick={handleCreateCoverLetter}>
              Create new Cover Letter
            </Button>
          </Flex>
          <CoverLettersTable
            handleDelete={(id) =>
              deleteCoverLetterMutation({ variables: { id } })
            }
          />
        </Box>
      </Box>
    </Box>
  );
};

export default CoverLettersPage;
